import Container from '@mui/material/Container';
import '../css/App.css';
import {Link} from 'react-router-dom'
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import myPhoto from '../img/me.jpg'
import Typography from "@mui/material/Typography";
import '@fontsource/roboto/300.css';
import * as React from "react";

function PictureOfMe() {
    return (
        <Container>
            <Box component="img" sx={{boxShadow: 3, borderRadius: '2%', objectFit: 'cover', pointerEvents: 'none', userSelect: 'none'}} src={myPhoto} alt={"Picture of Ryan Kilbride"} height='100%' width='100%' />
    </Container>
    );
}

export default function About() {
    return (
        <div>
            <Typography>
                <h1>About</h1>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={-1} display={{ xs: 'flex', md: 'none' }}>
                        <PictureOfMe/>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <h2>Ryan</h2>
                        Ryan Kilbride is a software developer with interests in artificial intelligence and 3D rendering.
                        When not at work, Ryan enjoys personal software projects, retro computing hardware, hiking photography,
                        motorcycling, film/cinematography, and a variety of other topics.
                        <p />
                        For professional inquiries please reach out via <Link to='https://www.linkedin.com/in/ryankilbride/'>LinkedIn</Link> or <Link to='/Resume'>request a resume</Link>.

                        <h2>This Site</h2>
                        ryankilbride.com and <Link to='https://www.rykil.dev'>rykil.dev</Link> are designed as a showcase of various projects, software development thoughts, and other collections of knowledge.
                        These sites are is also used as a as a way to learn some web technologies on the side, such as this site (React, Vercel, and AWS).
                        All opinions presented on ryankilbride.com in no way reflect employers or colleagues and are based on my own experiences in the
                        software development industry.
                    </Grid>

                    <Grid item display={{ xs: 'none', md: 'flex' }} md={4}>
                        <PictureOfMe/>
                    </Grid>

                </Grid>
            </Typography>

        </div>
    )
}
