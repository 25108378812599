import '../css/App.css';
import * as React from 'react';
import '@fontsource/roboto/300.css';
import Typography from "@mui/material/Typography";


function TimeOfDayMessage() {
    function getTimeOfDayString() {
        let date = new Date();
        let hr = date.getHours();

        if (hr < 4 || hr >= 18)
            return "Good evening.";
        if (hr < 12)
            return "Good morning.";
        if (hr < 18)
            return "Good afternoon.";
    }

    return (
        <Typography>
            <h1>{getTimeOfDayString()}</h1>
        </Typography>
    );
}

function Home() {
    //https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs

    return (
        <TimeOfDayMessage />
  );
}
Home.componentName = "Home";


export default Home;
