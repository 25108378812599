import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './css/index.css';
import Home from './pages/Home';
import Projects from './pages/Projects'
import Layout from './utilities/Layout'
import {Resume} from './pages/Resume'
import About from './pages/About'
import reportWebVitals from './utilities/reportWebVitals';
import Typography from "@mui/material/Typography";

const root = ReactDOM.createRoot(document.getElementById('root'));

function Err404() {
    return (
        <Typography>
            <h1>404 Not Found</h1>
            This page doesn't exist.
        </Typography>

    )
}

const siteLinks = [
    {title:"Home",      path:"/"},
    {title:"Projects",  path:"/Projects"},
    {title:"Resume",    path:"/Resume"},
    {title:"About",     path:"/About"}];

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route element={<Layout links={siteLinks} showBackground={true}  />}>
                    <Route index element={<Home />} />
                </Route>
                <Route element={<Layout links={siteLinks} showBackground={false}  />}>
                    <Route path="Projects" element={<Projects />} />
                    <Route path="Resume" element={<Resume />} />
                    <Route path="About" element={<About />} />
                    <Route path="*" element={<Err404 />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
