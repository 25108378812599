
import {Component, createRef} from "react";
import {Box, Button, TextField, Typography} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import * as React from "react";
import '@fontsource/roboto/300.css';

export class Resume extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: "",
            company: "",
            emailAddress: "",
            fullNameIsValid: true,//Initialize to true to prevent error text
            emailAddressIsValid: true,
            captchaFilled: false,
            formCompleted: false,
        }

        this.submitForm = this.submitForm.bind(this);
        this.validateName = this.validateName.bind(this);
        this.validateEmail = this.validateEmail.bind(this);

        this.captchaRef = createRef();
    }

    validateName() {
        let fullNameIsValid = !(this.state.fullName === "");
        this.setState(
            {
                fullNameIsValid: fullNameIsValid,
            });
        return fullNameIsValid;
    }

    validateEmail() {
        let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let emailAddressIsValid = !!this.state.emailAddress.match(emailRegex);

        this.setState(
            {
                emailAddressIsValid: emailAddressIsValid,
            });

        return emailAddressIsValid;
    }

    validateInput() {
        let cond1 = this.validateName();
        let cond2 = this.validateEmail();

        return cond1 && cond2 && this.state.captchaFilled;
    }

    postData(data = {}) {
        const request = {
            method: "POST",
            mode: "cors",
            body: data
        };

        const result = fetch(
            process.env.REACT_APP_RECAPTCHA_VERIFY_ENDPOINT,
            request);


        let fileName = "Kilbride,Ryan_Resume.pdf"
        result
            .then(response => response.blob())
            .then(data => {
                console.log(data)
                if (data.type !== "application/pdf") {
                    alert("Something went wrong. Please try again.");
                } else {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);


                    link.click();
                    window.URL.revokeObjectURL(url);
                    link.remove();

                    this.setState({formCompleted: true});
                    this.captchaRef.current.reset();
                    this.captchaRef = null;
                }

            })
    }

    submitForm(event) {
        event.preventDefault();
        setTimeout(() => {}, 5000);
        if (this.validateInput()) {
            const token = this.captchaRef.current.getValue();
            const body = {
                fullName: this.state.fullName,
                company: this.state.company,
                emailAddress: this.state.emailAddress,

                captchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
                captchaResponse: token
            };

            this.postData(JSON.stringify(body));
        }
    };

    render() {
        let SubmissionForm = (
            <div>
                <Typography >
                    <h1>Request a Resume</h1>
                    Thank you for your interest. Upon form submission, the download will begin.
                </Typography>
                <Box id="emailForm" component="form" maxWidth="300px" onSubmit={this.submitForm}>
                    <TextField required autoFocus
                               id="fullName"
                               variant="standard"
                               label="Full Name"
                               fullWidth={true}
                               helperText=" "
                               onChange={event => this.setState({ fullName: event.target.value })}
                               onBlur={this.validateName}
                               error={!this.state.fullNameIsValid}/>
                    <br/>
                    <TextField id="company"
                               variant="standard"
                               label="Company"
                               fullWidth={true}
                               onChange={event => this.setState({ company: event.target.value })}
                               helperText=" "/>
                    <br/>
                    <TextField required
                               id="emailAddress"
                               variant="standard"
                               label="Email Address"
                               fullWidth={true}
                               helperText="Your email address is not shared."
                               onBlur={this.validateEmail}
                               onChange={event => this.setState({ emailAddress: event.target.value })}
                               error={!this.state.emailAddressIsValid}/>
                    <br/>
                    <br/>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY }
                        ref={this.captchaRef}
                        onChange={() => this.setState({ captchaFilled: true })}
                        onExpired={() => this.setState({ captchaFilled: false })}/>
                    <br/>
                    <Button id="submit" type="submit">Submit</Button>
                </Box>
            </div>
        );
        let ThankYou = (
                <Typography>
                    <h1>Request a Resume</h1>
                    Thank you for your interest!
                </Typography>
            );


        return (
            <div>
                {this.state.formCompleted ? ThankYou : SubmissionForm}
            </div>
        )
    }
}