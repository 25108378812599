import { Outlet } from "react-router-dom";
import LayoutHeader from "./LayoutHeader";
import Container from "@mui/material/Container";
import LayoutFooter from "./LayoutFooter";
import * as React from "react";
import Box from "@mui/material/Box";
import background from "../img/Saddle_mountain_bkg.jpg";

function Background() {
    const backgroundStyles = {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        pointerEvents: 'none',
        userSelect: 'none',
        zIndex: -1
};

return (
    <Box position='fixed' width="100%" height="100%" sx={backgroundStyles}/>
)
}

export default function Layout(props) {

    return (
        <>
            {props.showBackground ? <Background/> : <div></div>}
            <LayoutHeader links={props.links} />
            <Container maxWidth="lg" className="App-text">
                <Outlet />
            </Container>
            <LayoutFooter />
        </>
    )
}