import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import '@fontsource/roboto/300.css';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import legohead_hero from '../img/legohead-hero.png'
import {Divider} from "@mui/material";
import {Link} from "react-router-dom";

function AppBrandLogo({collapsed}) {
    let sx1 = {display: {xs: 'none', md: 'flex'}, mr: 1, pointerEvents: 'none', userSelect: 'none'};
    let sx2 = {
        mr: 2,
        display: {xs: 'none', md: 'flex'},
        color: 'inherit',
        textDecoration: 'none',
        userSelect: 'none'
    };

    if (collapsed) {
        sx1 = { display: { xs: 'flex', md: 'none' }, mr: 1, pointerEvents: 'none', userSelect: 'none'};
        sx2 = {
            mr: 2,
            display: { xs: 'flex', md: 'none' },
            flexGrow: 1,
            color: 'inherit',
            textDecoration: 'none',
            userSelect: 'none'
        };
    }

    return (
        <>
            <Divider sx={sx1}>
                <img alt={"logo"} height={50} width={50} src={legohead_hero} />
            </Divider>


            <Typography variant="h6" noWrap component="a" sx={sx2}>
                ryankilbride.com
            </Typography>
        </>
    );
}


function LayoutHeader(props) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => { setAnchorElNav(event.currentTarget); };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const pages = props.links;

    return (
        <AppBar position="sticky" color="default">
            <Container maxWidth="lg" >
                <Toolbar disableGutters>
                    <AppBrandLogo />

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton size="large" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <Link key={page.path} to={page.path} style={{textDecoration: 'none'}}>
                                    <MenuItem onClick={handleCloseNavMenu}>
                                        <Typography fontFamily={'Roboto:300'} textAlign="center">{page.title}</Typography>
                                    </MenuItem>
                                </Link>
                            ))}
                        </Menu>
                    </Box>

                    <AppBrandLogo collapsed={true}/>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Link key={page.path} to={page.path} style={{textDecoration: 'none'}}>
                                <Button
                                    onClick={() => {}}
                                    sx={{ my: 2, color: 'black', display: 'block', textTransform: 'none', fontSize: '16px'}}
                                >
                                    {page.title}
                                </Button>
                            </Link>
                        ))}
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default LayoutHeader;
