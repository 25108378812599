import '@fontsource/roboto/300.css'
import Typography from "@mui/material/Typography";
import * as React from "react";

function Projects() {
    return (
        <Typography>
            <h1>Projects</h1>
            Project showcases have moved. Please visit <a href="https://www.rykil.dev/">rykil.dev</a> for project content!
        </Typography>
    )
}
Projects.componentName = "Projects";

export default Projects;