import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar"
import linkedin from "../img/linkedin-color.svg";
import ig from "../img/instagram-color.svg";
import github from "../img/github.svg";
import * as React from "react";
import Typography from "@mui/material/Typography";
import '@fontsource/roboto/300.css';
import {Link} from "react-router-dom";


function ExternalLinks() {
    const icons = [
        [linkedin, 'LinkedIn', 'https://www.linkedin.com/in/ryankilbride'],
        [github, 'GitHub', 'https://github.com/rykil/'],
        [ig, 'Instagram','https://www.instagram.com/ry.kil/'],
    ];


    return (
        <Grid container justifyContent='flex-end'>
            {icons.map(([img, alt, href]) => (
                <Link key={href} to={href}>
                    <IconButton size="large"  color="inherit">
                        <Box component="img" src={img} alt={alt} height='32px' width='32px' sx={{pointerEvents: 'none', userSelect: 'none'}}/>
                    </IconButton>
                </Link>
            ))}
        </Grid>
    );
}

function LayoutFooter() {

    const style = {
        stretch: { width: "100%" },
        item: { display: "flex", flexDirection: "row" },
        color: 'gray'
    };

    return (
        <>
            <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom:0  }}>
                <Toolbar>
                    <Container maxWidth={'lg'}>
                        <Grid container justifyContent="flex-end">
                            <Grid item sx={style} xs={6}>
                                <Box >
                                    <Typography sx={{ textAlign:'left', height: '50px', lineHeight:'50px', userSelect: 'none'}}>
                                        {new Date().toLocaleString('en-us', {month: 'long', year: "numeric"})} - Ryan Kilbride.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <ExternalLinks/>
                            </Grid>
                        </Grid>
                    </Container>
                </Toolbar>
            </AppBar>
            <Toolbar/>
        </>

    );
}

export default LayoutFooter;